import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index.vue'
import ProductService from '../views/productService/index.vue'
import CaseService from '../views/caseService/index.vue'
import AboutCompany from '../views/aboutCompany/index.vue'
import commerceLive from '../views/commerceLive/index.vue'
import commerceLiveRoom from '../views/commerceLiveRoom/index.vue'
import PageLayout from '../views/pageLayout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/lantu',
    name: 'lantu',
    component: PageLayout,
    children: [
      {
        path: 'home',
        name: 'home',
        component:Home,
      },
      {
        path: 'productService',
        name: 'productService',
        component:ProductService,
      },
      {
        path: 'caseService',
        name: 'caseService',
        component:CaseService,
      },
      {
        path: 'aboutCompany',
        name: 'aboutCompany',
        component:AboutCompany,
      },
      {
        path: 'commerceLive',
        name: 'commerceLive',
        component: commerceLive
      },
      {
        path: 'commerceLiveRoom',
        name: 'commerceLiveRoom',
        component: commerceLiveRoom
      }
    ]
  },
  {
    path: '/',
    redirect: "/lantu/commerceLive"
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next)=>{
  let localStorageTabs = localStorage.getItem('activeTabs')
  if(to.name == 'home' && localStorageTabs != undefined && localStorageTabs != null){
    localStorage.setItem('activeTabs',0)
  }
  window.scrollTo(0,0)
  next()
})

export default router
