<template>
  <div class="page">
    <!-- 首页banner -->
    <div class="productServiceBanner">
        <img src="../../assets/img/home/home_banner.jpg" alt="" class="bannerImg">
      <!-- <div class="bannerWrap">
        <div class="title">蓝图智库·为国编程</div>
        <div class="subTitle">
            致力于“行政+技术”双轮驱动的数字社会治理和数字经济创新发展模
        </div>
        <div class="subTitle">式，为地方政府提供专业的一站式智慧化数字产品解决方案和数字社会</div>
        <div class="subTitle">运营服务。</div>
      </div> -->
    </div>
    <!-- 场景应用 -->
    <div class="box_wrap">
        <h3>场景应用</h3>
        <p class="box_tit2">立足建设数字中国战略，定制数字政府整体解决方案，以优质的产品和服务做好示范案例，夯实数字服务与智慧运维能力，全面赋能数字社会发展</p>
        <ul class="scece">
            <li v-for="(item, index) in sceneList" :key="index" :class="{sceceItem_act: sceneTouch == index}" @mouseover="sceneTouch = index">
                <img :src="item.bgImg" alt="" class="scene_bg">
                <div class="sceneItem">
                    <img :src="item.icon" alt="" class="scene_icon">
                    <p class="scene_tit">{{ item.title }}</p>
                    <p class="scene_info" :style="sceneTouch == index ? 'opacity: 1;height: 26px;transform: translateY(0);' : 'opacity: 0;height: 0;transform: translateY(20px);'">{{ item.info }}</p>
                </div>
            </li>
        </ul>
    </div>
    <!-- 服务案例 -->
    <div class="box_wrap" style="background: #EAF0F6;">
        <h3>服务案例</h3>
        <p class="box_tit2">以蓝图鸿腾实例库为核心，以“县-市-省”逐级递进为发展脉络，形成数字社会底座、县域治理、市域治理、省域管理服务能力</p>
        <div class="service">
            <ul>
                <li v-for="(item, index) in seviceTab" :key="index" @mouseover="serviceClick(index)">
                    <img :src="item.icon" alt="">
                    <span>{{ item.text }}</span>
                    <i :class="{serviceTabAct: serviceTabIndex == index}"></i>
                </li>
            </ul>
        </div>
        <div class="service_info">
            <img src="@/assets/img/home/service_bg.png" alt="" class="service_bg">
            <div class="swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide swiper-no-swiping" v-for="(item, index) in serviceList" :key="index">
                        <img :src="item.logo" alt="">
                        <p class="name">{{ item.name }}</p>
                        <p class="info">{{ item.msg }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import "swiper/swiper-bundle.min.css"
import Swiper from "swiper"
export default {
  components: {
    
  },
  data () {
    return {
        isAboutLantu: false,
        sceneList: [
            {
                bgImg: require('/src/assets/img/home/scene_bgimg (3).png'),
                icon: require('/src/assets/img/home/scene_icon (2).png'),
                title: '数字政府',
                info: '建设数字政府促进治理能力现代化解决方案'
            },
            {
                bgImg: require('/src/assets/img/home/scene_bgimg (1).png'),
                icon: require('/src/assets/img/home/scene_icon (3).png'),
                title: '数字治理',
                info: '数据安全与数据要素一体化的治理解决方案'
            },
            {
                bgImg: require('/src/assets/img/home/scene_bgimg (4).png'),
                icon: require('/src/assets/img/home/scene_icon (4).png'),
                title: '数字经济',
                info: '智慧商业可视化管理解决方案'
            },
            {
                bgImg: require('/src/assets/img/home/scene_bgimg (2).png'),
                icon: require('/src/assets/img/home/scene_icon (2).png'),
                title: '数字民生',
                info: '以人民为中心的服务一体化智慧民生解决方案'
            },
            {
                bgImg: require('/src/assets/img/home/scene_bgimg (5).png'),
                icon: require('/src/assets/img/home/scene_icon (1).png'),
                title: '数字财政',
                info: '资金存管本地化效益化的解决方案'
            }
        ],
        sceneTouch: 0,
        seviceTab: [
            {
                icon: require('/src/assets/img/home/service_icon (3).png'),
                text: '本地金融服务实例库'
            },
            {
                icon: require('/src/assets/img/home/service_icon (2).png'),
                text: '县域数字社会实例库'
            },
            {
                icon: require('/src/assets/img/home/service_icon (4).png'),
                text: '城市治理感知实例库'
            },
            {
                icon: require('/src/assets/img/home/service_icon (2).png'),
                text: '数字乡村治理实例库'
            }
        ],
        serviceTabIndex: 0,
        serviceList: [
            {
                logo: require('/src/assets/img/home/service_logo (2).png'),
                name: '案例：成达',
                msg: '以聚合支付模块与商业新基础设施模块共同构建数字社会经济底座，以数字金融为抓手提供一揽子商业、行政场景解决方案，实现“金融+政府+行业+用户”的共生共荣生态。'
            },
            {
                logo: require('/src/assets/img/home/service_logo (3).png'),
                name: '案例：多多评',
                msg: '集数字社会、数字政府、数字经济一体的“多多评·码上生活”智能综合服务平台，用数字化手段构建起横向联合、纵向赋权、数字赋能的智能社会治理体系，探索县域数字创新发展模式。'
            },
            {
                logo: require('/src/assets/img/home/service_logo (3).png'),
                name: '案例：城市大脑',
                msg: '具备动态感知、智慧思考、迭代进化能力的城市大脑平台，真正实现城市各个产业、系统数据的互联互通和城市一体化建设，赋能城市感知决策，全面提升城市现代化管理水平。'
            },
            {
                logo: require('/src/assets/img/home/service_logo (1).png'),
                name: '案例：多多品',
                msg: '以服务乡村振兴与乡村治理、促进乡村高质量发展为根本，通过打通供应端与需求端助力本地农产品品牌建设，带动农民增收、农业增效，与城市大脑共同构成对市域城乡统筹服务能力。'
            }
        ],
        swiper: null
    }
  },
  mounted () {
    this.swiper = new Swiper(".swiper", {
        direction : 'vertical',
        speed: 400
    });
  },
  methods: {
    serviceClick (index) {
        this.serviceTabIndex = index
        this.swiper.slideTo(index)
    },
  }
};
</script>

<style scoped lang="less">
    p{
        margin: 0;
    }
    .aboutLantu{
        transition: all .5s linear;
    }
    .productServiceBanner {
        width: 100%;
        position: relative;
        .bannerImg{
            display: block;
            width: 100%;
            height: auto;
        }
        .bannerWrap{
            width: 1200px;
            margin: 0 auto;
            position: absolute;
            top: 286px;
            left: 0;
            right: 0;
            .title {
                font-family: PingFangSC-SNaNpxibold;
                font-weight: 600;
                font-size: 60px;
                color: #262626;
                letter-spacing: 0;
                line-height: 68px;
                margin-bottom: 16px;
            }
            .subTitle {
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 20px;
                color: #757575;
                letter-spacing: 0;
                line-height: 32px;
            }
        }
    }
    .box_wrap{
        padding: 90px 0 0;
        >h3{
            display: block;
            text-align: center;
            font-family: PingFangSC-SNaNpxibold;
            font-weight: 600;
            font-size: 40px;
            color: #262626;
            line-height: 48px;
            margin: 0 0 14px;
        }
        .box_tit2{
            width: 726px;
            margin: 14px auto 42px;
            font-family: PingFang-SC-Regular;
            font-weight: 400;
            font-size: 18px;
            color: #747270;
            text-align: center;
            line-height: 26px;
        }
        .scece{
            width: 1200px;
            margin: 0 auto 140px;
            display: flex;
            justify-content: space-between;
            li{
                width: 170px;
                height: 480px;
                border-radius: 6px;
                overflow: hidden;
                position: relative;
                cursor: pointer;
                transition: all .3s linear;
                .scene_bg{
                    display: block;
                    width: 482px;
                    height: 100%;
                }
                .sceneItem{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    padding: 0 0 48px 32px;
                    display: flex;
                    flex-flow: column;
                    justify-content: flex-end;
                    overflow: hi;
                    .scene_icon{
                        display: block;
                        width: 42px;
                        height: 38px;
                        margin-bottom: 24px;
                    }
                    .scene_tit{
                        font-family: PingFangSC-SNaNpxibold;
                        font-weight: 600;
                        font-size: 28px;
                        color: #FFFFFF;
                        line-height: 34px;
                    }
                    .scene_info{
                        font-family: PingFangSC-Regular;
                        font-weight: 400;
                        font-size: 18px;
                        color: #FFFFFF;
                        line-height: 26px;
                        height: 26px;
                        overflow: hidden;
                        margin-top: 8px;
                        transition: all .5s linear;
                    }
                }
            }
            .sceceItem_act{
                width: 482px;
                height: 480px;
            }
        }
        .service{
            width: 1200px;
            margin: 0 auto;
            ul{
                width: 1200px;
                margin: 0 auto 64px;
                display: flex;
                justify-content: space-between;
                border-bottom: 6px rgba(41, 83, 255, .06) solid;
                border-radius: 0 0 8px 8px;
                box-sizing: border-box;
                li{
                    width: 300px;
                    box-sizing: border-box;
                    cursor: pointer;
                    img{
                        display: block;
                        width: 48px;
                        height: 48px;
                        margin: 0 auto 16px;
                    }
                    span{
                        display: block;
                        font-family: PingFangSC-Medium;
                        font-weight: 500;
                        font-size: 24px;
                        color: #1D2023;
                        text-align: center;
                        line-height: 32px;
                    }
                    i{
                        display: block;
                        margin: 24px auto -6px;
                        width: 0;
                        height: 6px;
                        border-radius: 0 0 8px 8px;
                        transition: all .5s linear;
                    }
                    .serviceTabAct{
                        width: 300px;
                        background: #2953FF;
                    }
                }
                li:nth-of-type(3){
                    img{
                        
                    }
                }
            }
        }
        .service_info{
            height: 460px;
            display: flex;
            padding-bottom: 126px;
            box-sizing: content-box;
            .service_bg{
                display: block;
                width: 60%;
                height: auto;
            }
            .swiper{
                flex: 1;
                background: linear-gradient(138deg, #0F5EFD 6%, #4B89FF 100%);
                .swiper-wrapper{
                    .swiper-slide{
                        padding: 64px 60px 0;
                        img{
                            display: block;
                            width: 60px;
                            height: 60px;
                            margin-bottom: 32px;
                        }
                        p{
                            color: #fff;
                            max-width: 577px;
                        }
                        .name{
                            font-family: PingFangSC-SNaNpxibold;
                            font-weight: 600;
                            font-size: 32px;
                            line-height: 40px;
                            margin-bottom: 14px;
                        }
                        .info{
                            font-family: PingFangSC-Regular;
                            font-weight: 300;
                            font-size: 18px;
                            line-height: 26px;
                        }
                    }
                }
            }
        }
    }
</style>
