<template>
  <div class="commerce-live-container">
    <!-- banner图 start-->
    <div class="live-banner-wrapper">
      <img src="./assets/banner.png" alt="" />
    </div>
    <!-- end banner图 -->
    <!-- 直播间 start -->
    <div class="live-room-wrapper">
      <!-- 标题 -->
      <h2 class="common-title title">直播间</h2>
      <!-- 直播列表 -->
      <div class="live-ing-wrap">
        <div
          class="ing-item"
          v-for="(item, index) in liveList"
          :key="index"
          :class="{ liveActive: activeIndex == index }"
          @mouseover="activeIndex = index"
          @click="toRoom(item, 'live')"
        >
          <img v-if="activeIndex == index" :src="item.imgUrl" alt="" />
          <img v-else :src="item.imgSmUrl" alt="" />
          <div class="go-live-box" v-if="activeIndex == index">进入直播间</div>
        </div>
      </div>
      <!-- 直播回放 -->
      <div class="live-playback-wrap">
        <h3 class="common-small-title small-title">直播回放</h3>
        <div class="playback-list-wrap">
          <div
            class="list-item"
            v-for="(item, index) in playbackList"
            :key="index"
            @click="toRoom(item, 'playblack')">
            <img :src="item.imgUrl" class="top-box" alt="" />
            <div class="bottom-box">
              <div class="live-title-text">{{ item.title }}</div>
              <div class="bottom-count">
                <span>{{ item.room }}</span>
                <div class="count">
                  <img src="./assets/icon-1.png" alt="" />
                  <span>{{ item.nums }}万</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end 直播间 -->
    <!-- 服务案例 start -->
    <div class="live-cases-wrapper">
      <h2 class="common-title title">服务案例</h2>
      <div class="common-sub-title sub-title">蓝图智库协助鄂尔多斯市打造“多多品”助农平台，以“直播带货”形式，推荐当地特色农副产品，以“小屏幕”对接“大市场”，助力地方政府乡村振兴发展。</div>
      <div class="cases-list-wrap">
        <div class="case-list-item" v-for="(item, index) in caseList" :key="index">
          <div class="left-text-box">
            <h3>{{ item.title }}</h3>
            <p>{{ item.description }}</p>
          </div>
          <div class="right-img-box">
            <img :src="item.imgUrl" alt="" />
          </div>
        </div>
      </div>
      <!-- 多多品助农直播 -->
      <div class="cases-live-intro-wrap">
        <div class="intro-text-box">
          <h3 class="common-small-title">“多多品”助农直播</h3>
          <p class="common-sub-title">截至2023年4月底，累计开展直播带货<span>25</span>场，包含近<span>50余种</span>当地农副产品，观看人数累计<span>12000余人</span>，点赞数<span>30000余次</span>，点燃了电商助力乡村振兴的“新引擎”。</p>
        </div>
        <div class="intro-img-box">
          <img src="./assets/case.png" alt="" />
        </div>
      </div>
    </div>
    <!-- end 服务案例 -->
    <!-- 已服务旗区 start -->
    <div class="live-flag-district-wrapper">
      <h2 class="common-title title">已服务旗区</h2>
      <div class="common-sub-title sub-title">自2022年9月起，“多多品”助农惠民平台与东胜区、伊金霍洛旗、达拉特旗、准格尔旗、杭锦旗、乌审旗、鄂托克旗、鄂托克前旗等地区的“驻村第一书记”合作开展直播带货，多渠道助农增收。</div>
      <div class="flag-district-list">
        <div class="list-item" v-for="(item, index) in districtList" :key="index">
          <img :src="item.icon" alt="" />
          <span>{{ item.title }}</span>
        </div>
      </div>
    </div>
    <!-- end 已服务旗区 -->
  </div>
</template>

<script>
  import { caseList, districtList, liveList, playbackList } from './config/index'
  export default {
    name: 'Commercelive',
    data () {
      return {
        caseList,
        districtList,
        liveList,
        playbackList,
        activeIndex: 0
      }
    },
    methods: {
      /**
       * @description: 跳转到直播间
       * @return
       */
      toRoom (item, type) {
        this.$router.push({
          name: 'commerceLiveRoom',
          query: {
            videoUrl: item.link,
            type
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .common-title {
    font-size: 40px;
    line-height: 48px;
    color: #262626;
    font-weight: 600;
  }
  .common-small-title {
    font-size: 32px;
    line-height: 48px;
    color: #262626;
    font-weight: 600;
  }
  .common-sub-title {
    font-size: 18px;
    line-height: 26px;
    color: #747270;
    font-weight: 400;
  }
  .commerce-live-container {
    box-sizing: border-box;
    width: 100%;
    // banner图
    .live-banner-wrapper {
      width: 100%;
      position: relative;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    // 直播间
    .live-room-wrapper {
      box-sizing: border-box;
      padding: 88px 0;
      background-color: #fff;
      width: 100%;
      .title {
        text-align: center;
        margin: 0;
      }
      // 直播列表
      .live-ing-wrap {
        display: flex;
        align-items: center;
        margin: 0 auto;
        width: 1180px;
        height: 540px;
        .ing-item {
          transition: all .3s linear;
          width: 198px;
          height: 292px;
          position: relative;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
          .go-live-box {
            width: 283px;
            height: 72px;
            background-image: linear-gradient(90deg, #2953FF 0%, #ffffff00 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 28px;
            line-height: 40px;
            color: #fff;
            font-weight: 600;
            position: absolute;
            top: 56px;
            left: 0;
          }
        }
        .liveActive {
          width: 600px;
          height: 388px;
          // background: #E2EBFE;
          // border: 3px solid #2953FF;
          border-radius: 4px;
        }
      }
      // 直播回放
      .live-playback-wrap {
        width: 1180px;
        margin: 0 auto 0 auto;
        .small-title {
          margin: 0 0 56px 29px;
        }
        .playback-list-wrap {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          .list-item {
            margin-bottom: 56px;
            &:hover {
              cursor: pointer;
              .bottom-box {
                .live-title-text {
                  color: #0029D3;
                }
              }
            }
            .top-box {
              width: 352px;
              height: 200px;
              // background: #CFDEFE;
              border-radius: 8px;
            }
            .bottom-box {
              margin-top: 16px;
              .live-title-text {
                font-size: 18px;
                line-height: 26px;
                font-weight: 500;
                color: #262626;
                margin-bottom: 8px;
              }
              .bottom-count {
                display: flex;
                align-items: center;
                justify-content: space-between;
                span {
                  font-size: 16px;
                  line-height: 22px;
                  font-weight: 400;
                  color: #757575;
                }
                .count {
                  display: flex;
                  align-items: center;
                  img {
                    display: block;
                    width: 18px;
                    height: 18px;
                    margin-right: 6px;
                  }
                }
              }
            }
          }
        }
      }
    }
    // 服务案例
    .live-cases-wrapper {
      width: 100%;
      height: 1206px;
      background: url("./assets/bj-2.png") no-repeat center center;
      background-size: 100% 1206px;
      box-sizing: border-box;
      padding-top: 88px;
      .title {
        text-align: center;
        margin: 0 0 14px 0;
      }
      .sub-title {
        text-align: center;
        width: 726px;
        margin: 0 auto;
      }
      .cases-list-wrap {
        width: 1184px;
        margin: 64px auto 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .case-list-item {
          width: 384px;
          height: 150px;
          background-image: linear-gradient(-62deg, #DBEDFC 0%, #EBF3FF 100%);
          border-radius: 8px;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          padding: 24px 20px;
          .left-text-box {
            h3 {
              font-size: 20px;
              line-height: 30px;
              color: #0029D3;
              font-weight: 600;
              margin: 0 0 8px 0;
            }
            p {
              font-size: 14px;
              line-height: 20px;
              color: #0029D3;
              font-weight: 400;
              margin: 0;
              width: 160px;
            }
          }
          .right-img-box {
            margin-left: 11px;
            img {
              display: block;
              width: 173px;
              height: 102px;
            }
          }
        }
      }
      .cases-live-intro-wrap {
        width: 1184px;
        margin: 120px auto 0 auto;
        display: flex;
        justify-content: space-between;
        .intro-text-box {
          margin-top: 40px;
          h3 {
            margin: 0 0 20px 0;
          }
          p {
            width: 400px;
            span {
              color: #0029D3;
              font-weight: 500;
            }
          }
        }
        .intro-img-box {
          img {
            display: block;
            width: 702px;
            height: 550px;
          }
        }
      }
    }
    // 已服务旗区
    .live-flag-district-wrapper {
      box-sizing: border-box;
      padding: 88px 0;
      background-color: #fff;
      .title {
        text-align: center;
        margin: 0 0 14px 0;
      }
      .sub-title {
        width: 820px;
        margin: 0 auto;
        text-align: center;
      }
      .flag-district-list {
        width: 1180px;
        display: flex;
        flex-wrap: wrap;
        margin: 62px auto 0 auto;
        .list-item {
          margin-right: 20px;
          width: 380px;
          height: 116px;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          padding-left: 42px;
          margin-bottom: 22px;
          img {
            display: block;
            width: 64px;
            height: 64px;
          }
          span {
            margin-left: 20px;
            font-size: 20px;
            line-height: 28px;
            font-weight: 500;
            color: #262626;
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:nth-child(3n+1) {
            background: url("./assets/bj-3.png") no-repeat center center;
            background-size: 100% 116px;
          }
          &:nth-child(3n+2) {
            background: url("./assets/bj-6.png") no-repeat center center;
            background-size: 100% 116px;
          }
          &:nth-child(3n+3) {
            background: url("./assets/bj-5.png") no-repeat center center;
            background-size: 100% 116px;
          }
        }
      }
    }
  }
</style>