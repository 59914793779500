<template>
  <div class="commerce-liveroom-container">
    <div class="common-container room-wrapper">
      <!-- 左侧直播间 -->
      <div class="room-left-box">
        <!-- 头部信息区域 -->
        <div class="left-top-intro">
          <div class="title">蓝图智库直播间</div>
          <div class="tool-bar">
            <div class="bar-item">
              <span>分类：</span>
              <span>助农直播</span>
            </div>
            <div class="bar-item">
              <img src="./assets/icon-user.png" alt="" />
              <span>23.4万人</span>
            </div>
            <div class="bar-item">
              <img src="./assets/icon-cloak.png" alt="" />
              <span>2023/12/26 18:00:00</span>
            </div>
          </div>
        </div>
        <!-- 视频区域 -->
        <div class="left-video-wrap">
          <video ref="myVideo" :src="videoUrl" class="video-box" :controls="type === 'playblack' ? true : false" width="100%" height="100%" autoplay></video>
          <!-- <img src="./assets/icon-play.png" alt="" /> -->
        </div>
      </div>
      <!-- 右侧聊天区域 -->
      <div class="room-right-box">
        <div class="right-top-intro">
          <div class="title-block-box">
            聊天互动
          </div>
        </div>
        <div class="right-im-content" ref="rightImContent">
          <div
            class="im-item"
            v-for="(item, index) in list"
            :key="index"
          >
            <img :src="item.imgUrl" alt="" />
            <div class="column-con">
              <span>{{ item.nickName }}</span>
              <div class="popover-text-block">{{ item.text }}</div>
            </div>
          </div>
          <div class="tooltip-wrapper">
            <input type="text" class="tooltip" placeholder="参与直播互动" v-model="content" @keyup.enter="pushDispatch" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { list } from './config/index'
  export default {
    name: 'commerceLiveRoom',
    data () {
      return {
        list,
        content: '',
        videoUrl: '',
        type: ''
      }
    },
    mounted() {
      this.scrollToBottom();
      this.videoUrl = this.$route.query.videoUrl
      this.type = this.$route.query.type
    },
    methods: {
      /**
       * @description: 页面加载完成时执行滚动到底部的操作
       * @return
       */
      scrollToBottom() {
        this.$nextTick(() => {
          let scrollElem = this.$refs.rightImContent;
          scrollElem.scrollTo({ top: scrollElem.scrollHeight + 100, behavior: 'smooth' })
        })
      },
      
      /**
       * @description: 发送聊天信息
       * 1、追加数据
       * 2、执行滚动到底部操作
       * 3、清空输入框
       */
      pushDispatch () {
        this.list.push({
          imgUrl: require("./assets/icon-default.png"),
          nickName: '小烤肉',
          text: this.content
        })
        this.scrollToBottom()
        this.content = ''
      }
    }
  }
</script>

<style lang="less" scoped>
  .common-container {
    width: 1200px;
    margin: 0 auto;
  }
  .commerce-liveroom-container {
    width: 100%;
    height: 949px;
    background: url("./assets/bj.png") no-repeat center center;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-top: 116px;
    .room-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .room-left-box {
        width: 812px;
        height: 705px;
        border-radius: 12px;
        .left-top-intro {
          width: 100%;
          height: 96px;
          background-color: #fff;
          box-sizing: border-box;
          padding: 20px 0 0 24px;
          .title {
            font-size: 18px;
            line-height: 26px;
            font-weight: 500;
            color: #262626;
          }
          .tool-bar {
            margin-top: 8px;
            display: flex;
            .bar-item {
              display: flex;
              align-items: center;
              margin-right: 32px;
              span {
                font-size: 14px;
                line-height: 22px;
                color: #757575;
                font-weight: 400;
              }
              img {
                display: block;
                width: 16px;
                height: 16px;
                margin-right: 4px;
              }
            }
          }
        }
        .left-video-wrap {
          width: 100%;
          height: 609px;
          background-color: #F3F3F3;
          .video-box {
            display: block;
            object-fit: cover;
          }
        }
      }
      .room-right-box {
        width: 364px;
        height: 705px;
        background-color: #fff;
        border-radius: 12px;
        position: relative;
        .right-top-intro {
          width: 100%;
          height: 46px;
          display: flex;
          justify-content: center;
          align-items: center;
          .title-block-box {
            width: 110px;
            height: 44px;
            border-bottom: 2px solid #2953FF;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            color: #2953FF;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .right-im-content {
          width: 100%;
          height: 659px;
          overflow-y: scroll;
          background-color: #fff;
          box-sizing: border-box;
          padding: 24px 20px 48px 20px;
          .tooltip-wrapper {
            width: 100%;
            height: 52px;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 0;
            left: 0;
            .tooltip {
              width: 340px;
              height: 40px;
              background: #FAFAFA;
              border: 1px solid #F5F5F5;
              border-radius: 4px;
              display: flex;
              align-items: center;
              font-size: 14px;
              box-sizing: border-box;
              padding-left: 16px;
              &::placeholder {
                font-size: 14px;
                color: #595959;
              }
            }
          }
          
          &::-webkit-scrollbar-track-piece { //滚动条凹槽的颜色，还可以设置边框属性
            background-color:#f8f8f8;
          }
          &::-webkit-scrollbar {//滚动条的宽度
            width:9px;
            height:9px;
          }
          &::-webkit-scrollbar-thumb {//滚动条的设置
            background-color:#dddddd;
            background-clip:padding-box;
            min-height:28px;
          }
          &::-webkit-scrollbar-thumb:hover {
            background-color:#bbb;
          }
          .im-item {
            margin-bottom: 14px;
            display: flex;
            align-items: center;
            img {
              display: block;
              width: 32px;
              height: 32px;
              margin-right: 8px;
            }
            .column-con {
              span {
                font-size: 12px;
                line-height: 12px;
                color: #595959;
                font-weight: 400;
              }
              .popover-text-block {
                box-sizing: border-box;
                padding: 4px 8px;
                background-color: #E1EBFF;
                border-radius: 0 4px 4px 4px;
                font-size: 14px;
                line-height: 22px;
                color: #262626;
                margin-top: 4px;
              }
            }
          }
        }
      }
    }
  }
</style>