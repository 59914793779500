// 服务案例数据
export const caseList = [
  {
    title: '乡村振兴',
    description: '依托“多多品”助农平台，协助地方政府打造乡村振兴、数字乡村新抓手',
    imgUrl: require('../assets/pic-1.png')
  },
  {
    title: '直播赋能',
    description: '直播推介地方特色农副产品，赋能产品“走出去”、产业“强起来”',
    imgUrl: require('../assets/pic-2.png')
  },
  {
    title: '助农惠农',
    description: '携手地方政府探索直播创新发展模式，数字经济引领多渠道助农增收',
    imgUrl: require('../assets/pic-3.png')
  }
]

// 已服务旗区数据
export const districtList = [
  { icon: require("../assets/icon-dongsheng.png"), title: '东胜区', link: '' },
  { icon: require("../assets/icon-yiqi.png"), title: '伊金霍洛旗', link: '' },
  { icon: require("../assets/icon-daqi.png"), title: '达拉特旗', link: '' },
  { icon: require("../assets/icon-zhunqi.png"), title: '准格尔旗', link: '' },
  { icon: require("../assets/icon-hangjinqi.png"), title: '杭锦旗', link: '' },
  { icon: require("../assets/icon-wushenqi.png"), title: '乌审旗', link: '' },
  { icon: require("../assets/icon-etuoke.png"), title: '鄂托克旗', link: '' },
  { icon: require("../assets/icon-etuokeqianqi.png"), title: '鄂托克前旗', link: '' },
  { icon: require("../assets/icon-more.png"), title: '更多地区', link: '' }
]

// 直播列表数据
export const liveList = [
  {
    imgUrl: require('@/assets/img/live/live01.png'),
    imgSmUrl: require('@/assets/img/live/live_sm_01.png'),
    link: 'https://cdnfiles.ybddmzjb.cn/guanwang/%E7%9B%B4%E6%92%AD1.mp4'
  },
  {
    imgUrl: require('@/assets/img/live/live02.png'),
    imgSmUrl: require('@/assets/img/live/live_sm_02.png'),
    link: 'https://cdnfiles.ybddmzjb.cn/guanwang/%E7%9B%B4%E6%92%AD2.mp4'
  },
  {
    imgUrl: require('@/assets/img/live/live03.png'),
    imgSmUrl: require('@/assets/img/live/live_sm_03.png'),
    link: 'https://cdnfiles.ybddmzjb.cn/guanwang/%E7%9B%B4%E6%92%AD3.mp4'
  },
  {
    imgUrl: require('@/assets/img/live/live04.png'),
    imgSmUrl: require('@/assets/img/live/live_sm_04.png'),
    link: 'https://cdnfiles.ybddmzjb.cn/guanwang/%E7%9B%B4%E6%92%AD4.mp4'
  }
]

// 直播回放数据
export const playbackList = [
  {
    imgUrl: require('@/assets/img/live/playblack01.png'),
    title: '“多多品”助农惠民直播',
    room: '蓝图官方直播间',
    nums: 3.42,
    link: 'https://cdnfiles.ybddmzjb.cn/guanwang/%E7%9B%B4%E6%92%AD5.mp4'
  },
  {
    imgUrl: require('@/assets/img/live/playblack02.png'),
    title: '国企赋能乡村振兴',
    room: '蓝图官方直播间',
    nums: 1.26,
    link: 'https://cdnfiles.ybddmzjb.cn/guanwang/%E7%9B%B4%E6%92%AD6.mp4'
  },
  {
    imgUrl: require('@/assets/img/live/playblack03.png'),
    title: '“多多品”助农惠民直播',
    room: '蓝图官方直播间',
    nums: 0.98,
    link: 'https://cdnfiles.ybddmzjb.cn/guanwang/%E7%9B%B4%E6%92%AD7.mp4'
  },
  {
    imgUrl: require('@/assets/img/live/playblack04.png'),
    title: '电商助农——展洽会直播',
    room: '蓝图官方直播间',
    nums: 0.97,
    link: 'https://cdnfiles.ybddmzjb.cn/guanwang/%E7%9B%B4%E6%92%AD8.mp4'
  },
  {
    imgUrl: require('@/assets/img/live/playblack05.png'),
    title: '第五届农产品展洽会直播',
    room: '蓝图官方直播间',
    nums: 0.29,
    link: 'https://cdnfiles.ybddmzjb.cn/guanwang/%E7%9B%B4%E6%92%AD9.mp4'
  },
  {
    imgUrl: require('@/assets/img/live/playblack06.png'),
    title: '乡村振兴农产品直播',
    room: '蓝图官方直播间',
    nums: 2.16,
    link: 'https://cdnfiles.ybddmzjb.cn/guanwang/%E7%9B%B4%E6%92%AD10.mp4'
  }
]