<template>
  <div class="content">
    <!-- banner -->
    <div class="productServiceBanner">
      <img src="../../assets/img/service/service_banner.jpg" alt="" class="bannerImg">
      <!-- <div class="bannerWrap">
        <div class="title">聚合服务，数据赋能</div>
        <div class="subTitle">
          基于丰富实例库，匹配多种使用场景，覆盖多种行业需求，以“技术
        </div>
        <div class="subTitle">服务+运维赋能”服务模式，为用户提供一站式全生命周期服务解决</div>
        <div class="subTitle">方案。</div>
      </div> -->
    </div>
    <!-- 内容 -->
    <ul class="tabs">
      <li v-for="(item, index) in serviceTabs" :key="index" @click="tabChange(index)" :class="{tabsAct: serviceTabCurrent == index}">
        <a :href="'#serviceItem' + serviceTabCurrent">
          <img :src="serviceTabCurrent == index ? item.icon2 : item.icon1" alt="">
          <span>{{ item.text }}</span>
        </a>
      </li>
    </ul>
    <div class="ceilingTabsWrap" :style="isCeiling ? 'z-index: 2;opacity: 1;' : 'z-index: 0;opacity: 0;'">
      <ul class="ceilingTabs">
        <i :style="`left: ${ceilingBorder}px;`"></i>
        <li v-for="(item, index) in serviceTabs" :key="index" @click="tabChange(index)" :class="{liAct: serviceTabCurrent == index}">
          <a :href="'#serviceItem' + serviceTabCurrent">{{ item.text }}</a>
        </li>
      </ul>
    </div>
    <div class="services">
      <div class="serviceItem" v-for="(item, index) in serviceList" :key="index" :id="'serviceItem' + index">
        <img :src="item.img1.url" alt="" :class="item.img1.className" draggable="false">
        <img :src="item.img2.url" alt="" :class="item.img2.className" draggable="false">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      serviceTabs: [
        {
          icon1: require('@/assets/img/service/service_icon (3).png'),
          icon2: require('@/assets/img/service/service_icon (4).png'),
          text: '经济底座'
        },
        {
          icon1: require('@/assets/img/service/service_icon (6).png'),
          icon2: require('@/assets/img/service/service_icon (5).png'),
          text: '治理底座'
        },
        {
          icon1: require('@/assets/img/service/service_icon (2).png'),
          icon2: require('@/assets/img/service/service_icon (1).png'),
          text: '县域治理'
        },
        {
          icon1: require('@/assets/img/service/service_icon (8).png'),
          icon2: require('@/assets/img/service/service_icon (7).png'),
          text: '市域治理'
        }
      ],
      serviceTabCurrent: 0,
      serviceList: [
        {
          img1: {
            url: require('@/assets/img/service/service_1_1.png'),
            className: 'text'
          },
          img2: {
            url: require('@/assets/img/service/service_1.png'),
            className: 'img'
          }
        },
        {
          img1: {
            url: require('@/assets/img/service/service_2.png'),
            className: 'img'
          },
          img2: {
            url: require('@/assets/img/service/service_2_1.png'),
            className: 'text'
          }
        },
        {
          img1: {
            url: require('@/assets/img/service/service_3_1.png'),
            className: 'text'
          },
          img2: {
            url: require('@/assets/img/service/service_3.png'),
            className: 'img'
          }
        },
        {
          img1: {
            url: require('@/assets/img/service/service_4.png'),
            className: 'img'
          },
          img2: {
            url: require('@/assets/img/service/service_4_1.png'),
            className: 'text'
          }
        },
        {
          img1: {
            url: require('@/assets/img/service/service_5_1.png'),
            className: 'text'
          },
          img2: {
            url: require('@/assets/img/service/service_5.png'),
            className: 'img'
          }
        }
      ],
      isCeiling: false,
      ceilingBorder: 370
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.windowScroll)
    })
  },
  methods: {
    windowScroll() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 830) {
        this.isCeiling = true
      } else {
        this.isCeiling = false
      }
      if (scrollTop < 1070) {
        this.serviceTabCurrent = 0
        this.ceilingBorder = 370
      } else if (scrollTop > 1070 && scrollTop < 1930) {
        this.serviceTabCurrent = 1
        this.ceilingBorder = 514
      } else if (scrollTop > 1930 && scrollTop < 2800) {
        this.serviceTabCurrent = 2
        this.ceilingBorder = 658
      } else if (scrollTop > 2800) {
        this.serviceTabCurrent = 3
        this.ceilingBorder = 802
      }
    },
    tabChange (index) {
      this.serviceTabCurrent = index
      // switch(index){
      //   case 0:
      //     this.ceilingBorder = 633
      //     break
      //   case 1:
      //     this.ceilingBorder = 777
      //     break
      //   case 2:
      //     this.ceilingBorder = 921
      //     break
      //   case 3:
      //     this.ceilingBorder = 1075
      //     break
      // }
    }
  }
}
</script>

<style scoped lang="less">
.content{
  width: 100%;
  .productServiceBanner {
      width: 100%;
      position: relative;
      .bannerImg{
          display: block;
          width: 100%;
          height: auto;
      }
      .bannerWrap{
          width: 1200px;
          margin: 0 auto;
          position: absolute;
          top: 286px;
          left: 0;
          right: 0;
          .title {
              font-family: PingFangSC-SNaNpxibold;
              font-weight: 600;
              font-size: 60px;
              color: #262626;
              letter-spacing: 0;
              line-height: 68px;
              margin-bottom: 16px;
          }
          .subTitle {
              font-family: PingFangSC-Regular;
              font-weight: 400;
              font-size: 20px;
              color: #757575;
              letter-spacing: 0;
              line-height: 32px;
          }
      }
  }
  .tabs{
    width: 884px;
    display: flex;
    justify-content: space-between;
    margin: 84px auto 80px;
    li{
      width: 200px;
      height: 62px;
      background: rgba(240, 245, 255, .16);
      box-shadow: 0 0 16px 0 #F0F5FF;
      border-radius: 6px;
      transition: all .5s linear;
      a{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img{
        transition: all .5s linear;
        display: block;
        width: 22px;
        height: 22px;
        margin-right: 12px;
      }
      span{
        font-family: PingFangSC-SNaNpxibold;
        font-weight: 600;
        font-size: 18px;
        color: #262626;
      }
    }
    .tabsAct{
      background: #0F5EFD;
      span{
        color: #FFFFFF;
      }
    }
  }
  .ceilingTabsWrap{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0 2px 12px 0 #0000000a;
    // transition: all .5s linear;
    .ceilingTabs{
      width: 1200px;
      height: 72px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      i{
        display: block;
        width: 28px;
        height: 4px;
        background: #2953FF;
        border-radius: 2px;
        position: absolute;
        bottom: 8px;
        transition: all .5s linear;
      }
      li{
        a{
          display: block;
          margin: 0 40px;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          font-size: 16px;
          color: #262626;
          cursor: pointer;
          line-height: 48px;
        }
      }
      .liAct{
        font-weight: 600;
        color: #2953FF;
      }
    }
  }
  .services{
    .serviceItem{
      height: 790px;
      display: flex;
      justify-content: center;
      img{
        display: block;
      }
      .img{
        width: 700px;
        height: 550px;
        margin: 118px 28px 0;
        transition: all 1s linear;
        cursor: pointer;
      }
      .img:hover{
        transform: scale(1.1);
      }
      .text{
        width: 595px;
        height: 500px;
        margin: 206px 28px 0;
      }
    }
    #serviceItem2{
      .text{
        margin: 150px 28px 0;
      }
    }
    .serviceItem:nth-of-type(even){
      background: url(../../assets/img/service/service_bg.png) no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>