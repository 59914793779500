<template>
  <div class="productServiceContainer">
    <div class="productServiceBanner">
      <img src="./assets/banner.jpg" alt="" class="bannerImg">
      <!-- <div class="bannerWrap">
        <div class="title">数字驱动，智领未来</div>
        <div class="subTitle">
          赋能政府治理能力数字化，数字化技术与产业深度融合，优质的产品
        </div>
        <div class="subTitle">和服务实例库，提供专业的数字化一站式服务</div>
      </div> -->
    </div>
    <div class="productServiceBase">
      <!-- <div class="productServiceBaseTitle">蓝图鸿腾为基础，实例库为核心</div>
      <div class="productServiceSubTitle">
        基于自主研发的蓝图鸿腾开发工具，以丰富应用实例库为核心，提供“通用需求实例库模块组合+个性需求定制化开发”定制服务，兼具标准业务流程的快速开发与复杂业务需求的灵活定制，确保研发产品高度匹配数字化需求、适应各类复杂应用场景、具备多样化部署能力，降低软件开发门槛，缩短交付周期，优化数字化服务方式。
      </div>
      <div class="productServiceBtnContainer">
        <div
          :class="['productServiceBtn', item.isActive ? 'active' : '']"
          v-for="(item, index) in productServiceBtn"
          :key="index"
          @mousemove="changeActive(index)"
        >
          <img
            class="productServiceIcon"
            :src="item.isActive ? item.activeIcon : item.icon"
          />
          <div class="productServiceTitle">{{ item.title }}</div>
        </div>
      </div>
      <div
        :class="
          activeIndex == 0 ? 'productServiceBtnBg' : 'productServiceBtnBg1'
        "
      ></div> -->
      <img src="./assets/productSevice_banner.jpg" class="productSevice_banner" alt="">
    </div>
    <div class="productServiceDescContainer">
      <div class="productServiceTitle">服务模式</div>
      <div class="productServiceDesc">
        <div class="productServiceDescItem commonItem">
          <img src="./assets/productServiceDescItem.png" />
          <div class="productServiceDescItemTitle">技术输出</div>
          <div class="productServiceDescItemDesc">
            依据开发需求，完成知识产权、数据资源、结算资金归属本地的数字化产品开发交付
          </div>
        </div>
        <div class="productServiceDescItem1 commonItem">
          <img src="./assets/productServiceDescItem1.png" />
          <div class="productServiceDescItemTitle">运营赋能</div>
          <div class="productServiceDescItemDesc">
            整体输出标准化运营服务体系，协助地方政府探索数据要素价值运营相结合的可持续营收路径
          </div>
        </div>
      </div>
    </div>
    <!-- 服务优势 -->
    <div class="serviceAdvantageContainer">
      <div class="serviceAdvantageTitle">服务优势</div>
      <div class="serviceAdvantageRow" style="margin-bottom: 16px">
        <div
          class="serviceAdvantageItem"
          v-for="(item, index) in serviceList1"
          @mouseover="changeAdvan(index)"
          :key="index"
        >
          <div
            :style="{
              background: `url(${item.activeBg}) 0% 0% / 100% 100%`,
            }"
            v-if="serviceIndex1 == index"
            class="activeService"
          >
            <div class="activeServiceTitle">{{ item.activeTitle }}</div>
            <div class="activeServiceContent">{{ item.activeContent }}</div>
          </div>
          <div
            v-else
            class="serviceItem"
            :style="{
              background: `url(${item.bg}) 0% 0% / 100% 100%`,
            }"
          >
            <div class="serviceItemTitle">{{ item.title }}</div>
            <div class="serviceItemContent">{{ item.content }}</div>
          </div>
        </div>
      </div>
      <div class="serviceAdvantageRow">
        <div
          class="serviceAdvantageItem"
          v-for="(item, index) in serviceList2"
          :key="index"
          @mousemove="changeAdvan(index, 2)"
        >
          <div
            v-if="serviceIndex2 == index"
            class="activeService"
            :style="{
              background: `url(${item.activeBg}) 0% 0% / 100% 100%`,
            }"
          >
            <div class="activeServiceTitle">{{ item.activeTitle }}</div>
            <div class="activeServiceContent">{{ item.activeContent }}</div>
          </div>
          <div
            v-else
            class="serviceItem"
            :style="{
              background: `url(${item.bg}) 0% 0% / 100% 100%`,
            }"
          >
            <div class="serviceItemTitle">{{ item.title }}</div>
            <div class="serviceItemContent">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0,
      rowIndex: 0,
      serviceIndex1: 0,
      serviceIndex2: 0,
      serviceList1: [
        {
          title: "01",
          content: "通用性",
          activeTitle: "通用性",
          activeContent:
            "基于实例库，快速构建通用需求应用程序，且实例库可不断迭代更新",
          bg: require("./assets/01.png"),
          activeBg: require("./assets/01-active.png"),
        },
        {
          title: "02",
          content: "稳定性",
          activeTitle: "稳定性",
          activeContent: "代码结构化程度高、质量标准统一，便于维护",
          bg: require("./assets/02.png"),
          activeBg: require("./assets/02-active.png"),
        },
        {
          title: "03",
          content: "灵活性",
          activeTitle: "灵活性",
          activeContent: "实例组合与定制开发相结合，灵活应对各类需求",
          bg: require("./assets/03.png"),
          activeBg: require("./assets/03-active.png"),
        },
      ],
      serviceList2: [
        {
          title: "04",
          content: "连通性",
          activeTitle: "连通性",
          activeContent:
            "支持跨模块跨平台部署，打通上下游数据，整体交付产品集成内部各系统数据",
          bg: require("./assets/04.png"),
          activeBg: require("./assets/04-active.png"),
        },
        {
          title: "05",
          content: "低成本",
          activeTitle: "低成本",
          activeContent:
            "通过实例库成熟模块组合，降低应用场景重复开发的人力与沟通成本",
          bg: require("./assets/05.png"),
          activeBg: require("./assets/05-active.png"),
        },
        {
          title: "06",
          content: "高效率",
          activeTitle: "高效率",
          activeContent: "按需开发缩短交付周期，实例经验提高运维效率",
          bg: require("./assets/06.png"),
          activeBg: require("./assets/06-active.png"),
        },
      ],
      productServiceBtn: [
        {
          title: "蓝图鸿腾整体架构",
          icon: require("./assets/icon1.png"),
          activeIcon: require("./assets/icon1_active.png"),
          isActive: true,
        },
        {
          title: "蓝图鸿腾实例库",
          icon: require("./assets/icon2.png"),
          activeIcon: require("./assets/icon2_active.png"),
          isActive: false,
        },
      ],
    };
  },
  methods: {
    changeAdvan(index, rowType = 1) {
      if (index == this["serviceIndex" + rowType]) return;
      this["serviceIndex" + rowType] = index;
    },
    changeActive(activeIndex) {
      this.productServiceBtn.forEach((item, index) => {
        item.isActive = activeIndex == index;
      });
      this.activeIndex = activeIndex;
    },
  },
  beforeRouteEnter(to, from, next) {
    window.scrollTo(0, 0);
    next();
  },
};
</script>

<style lang="less" soped>
.productServiceContainer {
  width: 100%;
  .productServiceBanner {
      width: 100%;
      position: relative;
      .bannerImg{
          display: block;
          width: 100%;
          height: auto;
      }
      .bannerWrap{
          width: 1200px;
          margin: 0 auto;
          position: absolute;
          top: 286px;
          left: 0;
          right: 0;
          .title {
              font-family: PingFangSC-SNaNpxibold;
              font-weight: 600;
              font-size: 60px;
              color: #262626;
              letter-spacing: 0;
              line-height: 68px;
              margin-bottom: 16px;
          }
          .subTitle {
              font-family: PingFangSC-Regular;
              font-weight: 400;
              font-size: 20px;
              color: #757575;
              letter-spacing: 0;
              line-height: 32px;
          }
      }
  }
  .productServiceBase {
    // padding: 90px 0 120px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .productSevice_banner{
      display: block;
      width: 100%;
      height: auto;
    }
    .productServiceBaseTitle {
      font-family: PingFangSC-SNaNpxibold;
      font-weight: 600;
      font-size: 40px;
      color: #262626;
      line-height: 48px;
    }
    .productServiceSubTitle {
      margin: 14px 0 48px 0;
      width: 926px;
      height: 78px;
      font-family: PingFang-SC-Regular;
      font-weight: 400;
      font-size: 18px;
      color: #747270;
      text-align: center;
      line-height: 26px;
    }
    .productServiceBtnContainer {
      display: flex;
      justify-content: center;
      .productServiceBtn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 270px;
        height: 62px;
        padding: 0 46px;
        background: url("./assets/bg.png") no-repeat;
        background-size: 100% 100%;
        border-radius: 6px;
        margin-right: 28px;
        cursor: pointer;
        .productServiceIcon {
          width: 22px;
          height: 22px;
        }
        .productServiceTitle {
          font-family: PingFangSC-SNaNpxibold;
          font-weight: 600;
          font-size: 18px;
          color: #262626;
          letter-spacing: 0;
          line-height: 26px;
        }
      }
      .active {
        transition: all 0.6s ease;
        background: url("./assets/activeBg.png") no-repeat;
        background-size: 100% 100%;
        .productServiceTitle {
          color: #ffffff !important;
        }
      }
    }
    .productServiceBtnBg {
      margin-top: 24px;
      width: 1200px;
      height: 1015px;
      background: url("./assets/activeIndex1.png") no-repeat;
      background-size: 100% 100%;
    }
    .productServiceBtnBg1 {
      margin-top: 24px;
      width: 1200px;
      height: 1148px;
      background: url("./assets/activeIndex.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .productServiceDescContainer {
    width: 100%;
    height: 592px;
    background: url("./assets/productServiceDesc.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 100px;
    box-sizing: border-box;
    .productServiceTitle {
      font-family: PingFangSC-SNaNpxibold;
      font-weight: 600;
      font-size: 40px;
      color: #262626;
      line-height: 48px;
      text-align: center;
      margin-bottom: 64px;
    }
    .productServiceDesc {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .commonItem {
        box-sizing: border-box;
        transition: all 0.5s ease;
        position: relative;
        overflow: hidden;
        img {
          width: 590px;
          height: 100%;
          transition: all 0.5s ease;
        }
      }
      .commonItem:hover {
        img {
          transform: scale(1.1);
          transition: all 0.5s ease;
        }
        transform: translateY(-10px);
        box-shadow: 0 10px 24px 0 rgba(204, 221, 255, 0.12);
        transition: all 0.5s ease;
      }
      .productServiceDescItem {
        width: 590px;
        height: 240px;
        // background: url("./assets/productServiceDescItem.png") no-repeat;
        // background-size: 100% 100%;
        margin-right: 20px;
      }
      .productServiceDescItem1 {
        width: 590px;
        height: 240px;
        .commonItemImg {
          width: 100%;
          height: 100%;
        }
        // background: url("./assets/productServiceDescItem1.png") no-repeat;
        // background-size: 100% 100%;
      }
      .productServiceDescItemTitle {
        font-family: PingFang-SC-Heavy;
        font-weight: 900;
        font-size: 28px;
        color: #262626;
        line-height: 36px;
        margin-bottom: 10px;
        position: absolute;
        top: 40px;
        left: 32px;
      }
      .productServiceDescItemDesc {
        width: 277px;
        height: 90px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 18px;
        color: #595959;
        line-height: 30px;
        position: absolute;
        top: 90px;
        left: 32px;
      }
    }
  }
  .serviceAdvantageContainer {
    width: 100%;
    height: 773px;
    background: url("./assets/advBg.jpg") no-repeat;
    background-size: 100% 100%;
    padding: 100px 0 0 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    .serviceAdvantageTitle {
      font-family: PingFangSC-SNaNpxibold;
      font-weight: 600;
      font-size: 40px;
      color: #262626;
      line-height: 48px;
      margin-bottom: 48px;
    }
    .serviceAdvantageRow {
      display: flex;
      .serviceAdvantageItem {
        cursor: pointer;
        .activeService {
          width: 600px;
          height: 198px;
          border-radius: 8px;
          // background: url("./assets/serviceAdvantageItemActive.png") no-repeat;
          // background-size: 100% 100%;
          box-sizing: border-box;
          transition: all 0.5s ease;
          box-shadow: 0 10px 24px 0 rgba(204, 221, 255, 0.12);
          padding: 44px 0 0 48px;
          overflow: hidden;
          .activeServiceTitle {
            font-family: PingFang-SC-Heavy;
            font-weight: 900;
            font-size: 30px;
            color: #262626;
            line-height: 38px;
          }
          .activeServiceContent {
            width: 345px;
            height: 60px;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            font-size: 20px;
            color: #595959;
            line-height: 32px;
          }
        }
        .serviceItem {
          width: 284px;
          height: 198px;
          border-radius: 8px;
          position: relative;
          // background: url("./assets/serviceAdvantageItem.png") no-repeat;
          // background-size: 100% 100%;
          transition: all 0.5s ease;
          padding: 62px 0 0 48px;
          overflow: hidden;
          .serviceItemTitle {
            font-family: SourceHanSansCN-Heavy;
            font-weight: 900;
            font-size: 38px;
            color: #ccddff;
            line-height: 38px;
          }
          .serviceItemContent {
            font-family: PingFang-SC-Heavy;
            font-weight: 900;
            font-size: 30px;
            color: #262626;
            line-height: 38px;
          }
        }
      }
      .serviceAdvantageItem:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}
</style>