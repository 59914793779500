export const list = [
  {
    imgUrl: require("../assets/icon-default.png"),
    nickName: '小烤肉',
    text: '致敬每一份热爱'
  },
  {
    imgUrl: require("../assets/icon-default.png"),
    nickName: '小烤肉',
    text: '致敬每一份热爱'
  },
  {
    imgUrl: require("../assets/icon-default.png"),
    nickName: '小烤肉',
    text: '致敬每一份热爱'
  },
  {
    imgUrl: require("../assets/icon-default.png"),
    nickName: '小烤肉',
    text: '致敬每一份热爱'
  },
  {
    imgUrl: require("../assets/icon-default.png"),
    nickName: '小烤肉',
    text: '致敬每一份热爱'
  },
  {
    imgUrl: require("../assets/icon-default.png"),
    nickName: '小烤肉',
    text: '致敬每一份热爱'
  },
  {
    imgUrl: require("../assets/icon-default.png"),
    nickName: '小烤肉',
    text: '致敬每一份热爱'
  },
  {
    imgUrl: require("../assets/icon-default.png"),
    nickName: '小烤肉',
    text: '致敬每一份热爱'
  },
  {
    imgUrl: require("../assets/icon-default.png"),
    nickName: '小烤肉',
    text: '致敬每一份热爱'
  },
  {
    imgUrl: require("../assets/icon-default.png"),
    nickName: '小烤肉',
    text: '致敬每一份热爱'
  },
  {
    imgUrl: require("../assets/icon-default.png"),
    nickName: '小烤肉',
    text: '致敬每一份热爱'
  },
]