var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productServiceContainer"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"serviceAdvantageContainer"},[_c('div',{staticClass:"serviceAdvantageTitle"},[_vm._v("服务优势")]),_c('div',{staticClass:"serviceAdvantageRow",staticStyle:{"margin-bottom":"16px"}},_vm._l((_vm.serviceList1),function(item,index){return _c('div',{key:index,staticClass:"serviceAdvantageItem",on:{"mouseover":function($event){return _vm.changeAdvan(index)}}},[(_vm.serviceIndex1 == index)?_c('div',{staticClass:"activeService",style:({
            background: `url(${item.activeBg}) 0% 0% / 100% 100%`,
          })},[_c('div',{staticClass:"activeServiceTitle"},[_vm._v(_vm._s(item.activeTitle))]),_c('div',{staticClass:"activeServiceContent"},[_vm._v(_vm._s(item.activeContent))])]):_c('div',{staticClass:"serviceItem",style:({
            background: `url(${item.bg}) 0% 0% / 100% 100%`,
          })},[_c('div',{staticClass:"serviceItemTitle"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"serviceItemContent"},[_vm._v(_vm._s(item.content))])])])}),0),_c('div',{staticClass:"serviceAdvantageRow"},_vm._l((_vm.serviceList2),function(item,index){return _c('div',{key:index,staticClass:"serviceAdvantageItem",on:{"mousemove":function($event){return _vm.changeAdvan(index, 2)}}},[(_vm.serviceIndex2 == index)?_c('div',{staticClass:"activeService",style:({
            background: `url(${item.activeBg}) 0% 0% / 100% 100%`,
          })},[_c('div',{staticClass:"activeServiceTitle"},[_vm._v(_vm._s(item.activeTitle))]),_c('div',{staticClass:"activeServiceContent"},[_vm._v(_vm._s(item.activeContent))])]):_c('div',{staticClass:"serviceItem",style:({
            background: `url(${item.bg}) 0% 0% / 100% 100%`,
          })},[_c('div',{staticClass:"serviceItemTitle"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"serviceItemContent"},[_vm._v(_vm._s(item.content))])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productServiceBanner"},[_c('img',{staticClass:"bannerImg",attrs:{"src":require("./assets/banner.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productServiceBase"},[_c('img',{staticClass:"productSevice_banner",attrs:{"src":require("./assets/productSevice_banner.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productServiceDescContainer"},[_c('div',{staticClass:"productServiceTitle"},[_vm._v("服务模式")]),_c('div',{staticClass:"productServiceDesc"},[_c('div',{staticClass:"productServiceDescItem commonItem"},[_c('img',{attrs:{"src":require("./assets/productServiceDescItem.png")}}),_c('div',{staticClass:"productServiceDescItemTitle"},[_vm._v("技术输出")]),_c('div',{staticClass:"productServiceDescItemDesc"},[_vm._v(" 依据开发需求，完成知识产权、数据资源、结算资金归属本地的数字化产品开发交付 ")])]),_c('div',{staticClass:"productServiceDescItem1 commonItem"},[_c('img',{attrs:{"src":require("./assets/productServiceDescItem1.png")}}),_c('div',{staticClass:"productServiceDescItemTitle"},[_vm._v("运营赋能")]),_c('div',{staticClass:"productServiceDescItemDesc"},[_vm._v(" 整体输出标准化运营服务体系，协助地方政府探索数据要素价值运营相结合的可持续营收路径 ")])])])])
}]

export { render, staticRenderFns }