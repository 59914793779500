<template>
  <div class="page">
  <header class="comonHeader" :style="{ background: headerBgColor }">
    <div class="headerContent">
      <img
        src="../assets/img/logo.png"
        class="headerLogo"
      />
      <div class="headerTabs">
        <div class="itemGap" :style="{ left: `${transLateGap}px` }"></div>
        <div
          v-for="(tabsItem, tabsIndex) in tabsList"
          @click="changeTabs(tabsIndex)"
          :key="tabsIndex"
          class="tabsItem"
        >
          <div :class="['itemTitle', tabsItem.isActive ? 'active' : '']">
            {{ tabsItem.routeTitle }}
          </div>
        </div>
      </div>
    </div>
  </header>
  <router-view />
  <div class="commonFooter">
    <div class="box_6 flex-row">
      <div class="text-wrapper_3 flex-col">
        <span class="text_12">首页</span>
        <span @click="changeTabs(0)" class="text_13">为国编程</span>
        <span @click="changeTabs(0)" class="text_14">场景应用</span>
        <span @click="changeTabs(0)" class="text_15">服务案例</span>
      </div>
      <div class="text-wrapper_4 flex-col">
        <span class="text_16">产品与服务</span>
        <span @click="changeTabs(1)" class="text_17">产品概述</span>
        <span @click="changeTabs(1)" class="text_18">服务模式</span>
        <span @click="changeTabs(1)" class="text_19">服务优势</span>
      </div>
      <div class="text-wrapper_5 flex-col">
        <span class="text_20">服务案例</span>
        <span @click="changeTabs(2)" class="text_21">经济底座</span>
        <span @click="changeTabs(2)" class="text_22">治理底座</span>
        <span @click="changeTabs(2)" class="text_23">县域治理</span>
        <span @click="changeTabs(2)" class="text_24">市域治理</span>
      </div>
      <div class="text-wrapper_6 flex-col">
        <span class="text_25">关于蓝图</span>
        <span @click="changeTabs(3)" class="text_26">公司介绍</span>
        <span @click="changeTabs(3)" class="text_27">品牌文化</span>
        <span @click="changeTabs(3)" class="text_28">资质荣誉</span>
      </div>
      <div class="text-wrapper_7 flex-col">
        <span class="text_29">联系我们</span>
        <span class="text_30">青岛蓝图智库信息技术有限公司</span>
        <span class="text_31">电话:186&nbsp;0816&nbsp;6030</span>
        <span class="text_32">微信:1138817129</span>
        <span class="text_33">地址:青岛市崂山区海尔路金鼎大厦1909蓝图智库</span>
      </div>
      <div class="group_2 flex-col">
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="@/assets/img/SketchPng1fa0242b6700c7fbf0e2a7520e5ce248b126b9f2b37d51f0337918fa16580bc5.png"
        />
        <div class="text-wrapper_8 flex-col">
          <span class="text_34">扫码关注公众号</span>
        </div>
      </div>
    </div>
    <div class="box_7 flex-col">
      <div style="width: 1200px;margin: 0 auto;">
        <span class="text_35" style="width: auto;text-align: center;">Copyright&nbsp;©2020&nbsp;青岛蓝图智库信息技术有限公司&nbsp;版权所有</span
        >
        <div class="image-text_2 flex-row justify-between" style="width: 100%;display: flex;justify-content: center;">
          <img
            class="thumbnail_1"
            referrerpolicy="no-referrer"
            src="@/assets/img/SketchPng0367ae4414c85bf22efd635968611f9412828f0fb609ed9b7c9115ce0a54b4f0.png"
          />
          <span class="text-group_3" style="width: auto;">鲁ICP备20008373号-3 鲁网文(2022)2393-064号</span>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      transLateGap: 0,
      tabsList: [
        {
          routeUrl: "/lantu/home",
          routeName: "home",
          routeTitle: "首页",
          isActive: true,
          left: 1,
        },
        {
          routeUrl: "/lantu/productService",
          routeName: "productService",
          routeTitle: "产品与服务",
          isActive: false,
          left: 80,
        },
        {
          routeUrl: "/lantu/caseService",
          routeName: "caseService",
          routeTitle: "服务案例",
          isActive: false,
          left: 160
        },
        {
          routeUrl: "/lantu/aboutCompany",
          routeName: "aboutCompany",
          routeTitle: "关于蓝图",
          isActive: false,
          left: 240,
        },
        {
          routeUrl: "/lantu/commerceLive",
          routeName: "commerceLive",
          routeTitle: "助农直播",
          isActive: false,
          left: 320,
        }
      ],
      headerBgColor: "",
    };
  },
  methods: {
    windowScroll() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
        this.headerBgColor = scrollTop > 0 ? "#fff" :"none";
    },
    // 是否立即跳转
    changeTabs(activeIndex,isToPage = true,isRepeatRoute = true) {
      let activeItem = this.tabsList.find((item,index)=>index == activeIndex)
      if(isRepeatRoute && activeItem.routeName == this.$route.name) return
      localStorage.setItem('activeTabs',activeIndex)
      this.tabsList.forEach((item, index) => {
        if (activeIndex == index) {
          this.transLateGap = item.left;
          item.isActive = true;
        } else {
          item.isActive = false;
        }
      });
      if(!isToPage) return
      this.$router.push({
        name:activeItem.routeName
      })
    },
  },
  mounted() {
    // 设置路由缓存信息
    let localStorageTabs = localStorage.getItem('activeTabs')
    if(localStorageTabs === undefined || localStorageTabs === null){
      localStorage.setItem('activeTabs',0)
      this.changeTabs(0,false,false)
    }else{
      this.changeTabs(localStorageTabs,false,false)
    }
    this.$nextTick(() => {
      window.addEventListener("scroll", this.windowScroll);
    });
  },
};
</script>

<style scoped lang="less" src="@/assets/index.less" />
<style lang="less">
.comonHeader {
  width: 100%;
  padding: 26px 0;
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 370px;
  z-index: 2;
  transition: all 0.4s ease-in-out;
  .headerContent {
    display: flex;
    align-items: center;
    .headerLogo {
      width: 140px;
      height: 34px;
      margin-right: 64px;
    }
    .headerTabs {
      display: flex;
      width: 368px;
      justify-content: space-between;
      position: relative;
      .itemGap {
        width: 28px;
        height: 4px;
        background: #2953ff;
        border-radius: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all 0.4s ease-in-out;
      }
      .tabsItem {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .itemTitle {
          font-family: PingFangSC-Regular;
          font-weight: 400;
          font-size: 16px;
          color: #262626;
          line-height: 24px;
          margin-bottom: 8px;
          transition: all 0s ease-in-out 0.4s;
        }
        .active {
          font-family: PingFangSC-SNaNpxibold;
          font-weight: 600;
          font-size: 16px;
          color: #262626;
          transition: all 0s ease-in-out 0.4s;
        }
      }
    }
  }
}
</style>