<template>
  <div class="aboutCompanyContainer">
    <div class="aboutCompanyBanner">
      <img src="./assets/banner.jpg" alt="" class="bannerImg">
      <!-- <div class="bannerWrap">
        <div class="title">为国编程，为人民编程</div>
        <div class="subTitle">
          以蓝图实例库探索最佳实践，以自身技术和积累提供数字化服务，推动全社会数字化进程，助推数字中国建设发展，促进全民共享数字经济红利，共赢数字时代未来。
        </div>
      </div> -->
    </div>
    <div class="aboutCompanyDesc">
      <div class="aboutCompanyDescItem" style="margin-top: 160px">
        <div class="aboutCompanyDescTitle">关于蓝图</div>
        <div class="aboutCompanyDescBottom">
          <div class="bottomContent">
            蓝图智库成立于2019年，是一家依托数字化技术和互联网平台技术，致力于为数字社会提供全实例应用解决方案的高新技术企业。
            公司以数字技术为核心，拥有高素质、精技术、强经验的技术研发团队与运营服务团队，依托自主研发的开发工具——蓝图鸿腾，为地方政府提供专业的一站式智慧化数字产品解决方案和数字社会运营服务，立足数字经济发展新场景、新模式，与地方政府共同打造广泛、高效、易用、可信赖的数字化平台工具及数字经济创新发展模式，全面助力党管数据目标实现。
          </div>
          <div class="bottomImage"></div>
        </div>
      </div>
      <div class="aboutCompanyDescItem" style="margin-top: 120px">
        <div class="aboutCompanyDescTitle">品牌文化</div>
        <div class="aboutCulture">
          <div class="aboutCultureItem">
            <div class="aboutCultureImgContainer">
              <img
                src="./assets/aboutCultureImg1.png"
                class="aboutCultureImg"
              />
            </div>
            <div class="aboutCultureTitle">企业使命</div>
            <div class="aboutCultureSubTitle">
              以“为国编程”为使命，立足国家加快发展数字经济的重要部署，为地方政府提供“治理数字化、财政数字化”的数字技术服务与整体解决方案。
            </div>
          </div>
          <div class="aboutCultureItem">
            <div class="aboutCultureImgContainer">
              <img
                src="./assets/aboutCultureImg2.png"
                class="aboutCultureImg"
              />
            </div>
            <div class="aboutCultureTitle">发展道路</div>
            <div class="aboutCultureSubTitle">
              坚持“行政+技术”双轮驱动的数字社会治理和数字经济创新发展模式，致力于提升通过采集商业数据为社会治理服务的核心能力，进而从源头上解决“党管数据”的路径问题。
            </div>
          </div>
          <div class="aboutCultureItem">
            <div class="aboutCultureImgContainer">
              <img
                src="./assets/aboutCultureImg3.png"
                class="aboutCultureImg"
              />
            </div>
            <div class="aboutCultureTitle">价值追求</div>
            <div class="aboutCultureSubTitle">
              协助地方政府破题发展和治理解难，推动政府在行政执行力方面，趋向科学化、数据化（破解土地财政危机、防控资本无序扩张、拉动内需提振消费、有效实施数字治理、实现第三次分配）。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="aboutCompanyHonor">
      <div class="honorContainer">
        <div class="honorTitle">资质荣誉</div>
        <div class="honorSubTitle">
          高新技术企业、青岛市专精特新中小企业、青岛市新经济潜力企业、青岛市科技型中小企业、11项软著、15项专利技术
        </div>
        <div class="honerImgContainer">
          <div class="honerImgTop">
            <div class="honerImgItem">
              <div class="honerImg">
                <img src="./assets/honerImg1.png" />
              </div>
              <div class="honerImgTitle">高新技术企业</div>
            </div>
            <div class="honerImgItem">
              <div class="honerImg">
                <img src="./assets/honerImg2.png" />
              </div>
              <div class="honerImgTitle">青岛市专精特新中小企业</div>
            </div>
          </div>
          <div class="honerImgBottom">
            <div class="honerBottomImgItem">
              <div class="honerImg">
                <img src="./assets/honerImg3.jpg" />
              </div>
              <div class="honerImgTitle">15项专利技术</div>
            </div>
            <div class="honerBottomImgItem">
              <div class="honerImg">
                <img src="./assets/honerImg4.jpg" />
              </div>
              <div class="honerImgTitle">11项软著</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.aboutCompanyContainer {
  width: 100%;
  .aboutCompanyBanner {
    width: 100%;
    position: relative;
    .bannerImg{
        display: block;
        width: 100%;
        height: auto;
    }
    .bannerWrap{
        width: 1200px;
        margin: 0 auto;
        position: absolute;
        top: 286px;
        left: 0;
        right: 0;
        .title {
            font-family: PingFangSC-SNaNpxibold;
            font-weight: 600;
            font-size: 60px;
            color: #262626;
            letter-spacing: 0;
            line-height: 68px;
            margin-bottom: 16px;
        }
        .subTitle {
            width: 600px;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            font-size: 20px;
            color: #757575;
            letter-spacing: 0;
            line-height: 32px;
        }
    }
  }
  .aboutCompanyDesc {
    width: 100%;
    height: 1428px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    .aboutCompanyDescItem {
      width: 1210px;
      .aboutCompanyDescTitle {
        font-family: PingFangSC-SNaNpxibold;
        font-weight: 600;
        font-size: 46px;
        color: #262626;
        line-height: 54px;
      }
      .aboutCompanyDescBottom {
        margin-top: 24px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .bottomContent {
          width: 560px;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          font-size: 18px;
          color: #595959;
          line-height: 36px;
          margin-top: 20px;
        }
        .bottomImage {
          width: 520px;
          height: 342px;
          background: url("./assets/aboutBanner.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .aboutCulture {
        margin-top: 50px;
        width: 1210px;
        display: flex;
        justify-content: space-between;
        .aboutCultureItem {
          padding: 22px 22px 0 22px;
          width: 381px;
          height: 522px;
          box-sizing: border-box;
          cursor: pointer;
          transition: all 0.6s ease;
          .aboutCultureImgContainer {
            width: 337px;
            height: 234px;
            overflow: hidden;
            .aboutCultureImg {
              width: 337px;
              height: 234px;
              transition: all 0.6s ease;
            }
          }
          .aboutCultureTitle {
            font-family: PingFang-SC-Heavy;
            font-weight: 900;
            font-size: 28px;
            color: #262626;
            line-height: 36px;
            margin: 32px 0 10px 0;
          }
          .aboutCultureSubTitle {
            font-family: PingFangSC-Regular;
            font-weight: 400;
            font-size: 18px;
            color: #595959;
            line-height: 30px;
          }
        }
        .aboutCultureItem:hover {
          background: url(./assets/aboutCultureItemBg.png) no-repeat;
          background-size: 100% 100%;
          transition: all 0.6s ease;
          .aboutCultureImg {
            transform: scale(1.1);
            transition: all 0.6s ease;
          }
        }
      }
    }
  }
  .aboutCompanyHonor {
    width: 100%;
    height: 1740px;
    background-image: linear-gradient(135deg, #f9faff 0%, #eef1ff 88%);
    display: flex;
    justify-content: center;
    .honorContainer {
      width: 1210px;
      padding-top: 120px;
      box-sizing: border-box;
      .honorTitle {
        font-family: PingFangSC-SNaNpxibold;
        font-weight: 600;
        font-size: 46px;
        color: #262626;
        line-height: 54px;
      }
      .honorSubTitle {
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 18px;
        color: #595959;
        line-height: 36px;
        margin: 16px 0 42px 0;
      }
      .honerImgContainer {
        width: 100%;
        .honerImgTop {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .honerImgItem {
            width: 584px;
            cursor: pointer;
            .honerImg {
              width: 584px;
              height: 378px;
              overflow: hidden;
              border-radius: 12px;
              img {
                width: 584px;
                height: 378px;
                transition: all 0.6s ease;
              }
            }
            .honerImgTitle {
              margin-top: 20px;
              font-family: PingFangSC-Regular;
              font-weight: 400;
              font-size: 18px;
              color: #595959;
              text-align: center;
              line-height: 30px;
            }
          }
          .honerImgItem:hover {
            img {
              transform: scale(1.1);
              transition: all 0.6s ease;
            }
          }
        }
        .honerBottomImgItem {
          width: 100%;
          cursor: pointer;
          .honerImg {
            width: 1210px;
            height: 364px;
            margin-top: 48px;
            overflow: hidden;
            border-radius: 12px;
            img {
              width: 1210px;
              height: 364px;
              transition: all 0.6s ease;
            }
          }
          .honerImgTitle {
            margin-top: 20px;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            font-size: 18px;
            color: #595959;
            text-align: center;
            line-height: 30px;
          }
        }
        .honerBottomImgItem:hover {
          img {
            transform: scale(1.05);
            transition: all 0.6s ease;
          }
        }
      }
    }
  }
}
</style>